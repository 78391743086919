<template>
<!-- 短篇 故事设置 模板 -->
  <div>
    <el-row :gutter="10" class="my-1">

      <el-col :span="24" class="">
        <div class="template-box d-flex" v-if="activeItem.id == '-1'">
          <div class="d-flex flex-y-center pointer" @click="setShowDialogDpFlag(true)"  style="background: #FFFFFF;border-radius: 12px;padding: .5rem;border: 1px solid rgba(0,0,0,0.08);">
            <div class="template-icon flex-center" style="border-radius: 12px;width: 5rem;height: 5rem;background: #F3F4FF;">
              <span class="" style="font-size: 40px;color: #FFFFFF;padding-bottom: 8px;">+</span>
            </div>
            <div class="template-tips flex-center" style="height: 4rem;padding-left: 6px;">
              <span style="padding: 0 .5rem;">选择故事模板</span><i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>

        <div class="template-box d-flex" v-else-if="activeItem.id == '0'">
          <div class="d-flex flex-y-center pointer" @click="setShowDialogDpFlag(true)" style="border-radius: 6px;border: 1px solid #366EF4;width: auto;">
            <div class="template-icon d-flex " style="margin:.5rem;background: linear-gradient( 270deg, #F7F8FF 0%, #E7E9FE 100%);border-radius: 6px;padding: 1rem;flex-direction: column;">
              <span class="field-title" style="padding-bottom: 1rem;">不使用故事套路模板</span>
              <span class="field-content" style="">自由创作，不受约束！</span>
            </div>
            <div class="template-tips flex-center" style="height: 4rem;padding-left: 6px;padding-right: 6px;">
              <span></span><i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>

        <div class="template-box d-flex" style="" v-else>
          <div class="d-flex flex-y-center pointer" @click="setShowDialogDpFlag(true)" style="border-radius: 12px;border: 1px solid #366EF4;width: auto;">
            <div class="model-box" style="padding: .5rem;">
                <div class="model-box-item" style="padding: .5rem 0 .5rem 1rem;border-radius: 6px;">
                    <div class="model-box-item-title">
                      {{activeItem.title}}
                    </div>
                    <el-tooltip placement="top" class="ly-tooltip-class">
                      <div slot="content">
                        <div class="score-box d-flex" style="padding: .5rem  .5rem 0 .5rem;align-items: center;">
                          <div class="score-info d-flex" style="flex-direction: column;margin-right: 1rem;">
                            <div class="score-text" style="font-weight: 400;font-size: 12px;color: #FFFFFF;">
                              模板难度
                            </div>
                            <div class="score-number" style="font-weight: 600; font-size: 32px;color: rgba(255,255,255,0.9);">
                              {{Number(activeItem.score).toFixed(1)}}
                            </div>
                          </div>
                          <div class="score-star">
                            <div class="model-box-item-score d-flex" style="">
                              <div v-for="number in 5" :key="number" >
                                <img style="width: 16px; height: 16px;" v-if="number <= activeItem.score/2" src="../../assets/star-yellow.png" alt="" />
                                <img style="width: 16px; height: 16px;" v-else-if="number == (activeItem.score/2+0.5)" src="../../assets/star-half.png" alt="" />
                                <img style="width: 16px; height: 16px;" v-else src="../../assets/star-white.png" alt="" />
                              </div>
                            </div>
                            <div class="score-desc" style="font-size: 12px;line-height: 32px;color: #FFFFFF;">
                              {{activeItem.scoreDesc}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="model-box-item-score d-flex" style="">
                        <div v-for="number in 5" :key="number" >
                          <img style="width: 16px; height: 16px;" v-if="number <= activeItem.score/2" src="../../assets/star-yellow.png" alt="" />
                          <img style="width: 16px; height: 16px;" v-else-if="number == (activeItem.score/2+0.5)" src="../../assets/star-half.png" alt="" />
                          <img style="width: 16px; height: 16px;" v-else src="../../assets/star-white.png" alt="" />
                        </div>
                      </div>
                    </el-tooltip>
                    <div class="model-box-item-tag" style="margin-top: .1rem;">
                      <span class="model-box-item-tag-item" :key="indexTag" v-for="(itemTag,indexTag) in activeItem.tag&&activeItem.tag.split(',')">{{itemTag}}</span>
                    </div>
                </div>
            </div>
            <div class="template-tips flex-center" style="height: 100px;padding-right: .5rem;">
              <span></span><i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>

      </el-col>

      <el-col :span="24" class="my-1" v-if="notTemplateFlag && enums.ArticleTypeEnum.length>0">
        <span class="field-title">故事类型</span><span class="field-required">*</span>
      </el-col>
      <el-col :span="24" v-if="notTemplateFlag">
        <el-select class="custom-select-v1"
            v-model="story.articleType"
            filterable
            size="mini"
            placeholder="类型">
          <el-option
            v-for="(item,index) in enums.ArticleTypeEnum.filter((item) => {
            if(story.articleGenType == 'GEN_BY_ZHIHU' && item.code >= 1 && item.code <= 10){
              return true
            }else{
              return false
            }
          })"
            :label="item.desc"
            :key="index"
            :value="item.name"
          />
        </el-select>
      </el-col>
    </el-row>
<!-- 灵感助手 -->
    <el-row :gutter="10" class="ly-border-12" v-if="!notTemplateFlag && activeItem.id!='-1'" style="background: #FFFFFF;padding: 0 .5rem 1rem .5rem;">
      <el-col :span="12" class="my-1">
        <div class="jieshi-box">
          <div class="jieshi-box-title d-flex">
            <img class="img-header" src="../../assets/taolumuban-icon.png" style=""/><span>模板套路</span><img class="img-tail" src="../../assets/tishi-icon.png" style=""/>
          </div>
          <el-tooltip placement="top">
            <div slot="content">
              <div style="display: flex;width: 45vw;line-height: 1.5rem;">
                {{activeItem.content}}
              </div>
            </div>
            <div class="jieshi-box-content">
              {{activeItem.content}}
            </div>
          </el-tooltip>
        </div>
      </el-col>
      <el-col :span="12" class="my-1">
        <div class="jieshi-box">
          <div class="jieshi-box-title d-flex">
            <img class="img-header" src="../../assets/juesuesuuoming-icon.png" style=""/><span>角色说明</span><img class="img-tail" src="../../assets/tishi-icon.png" style=""/>
          </div>
          <el-tooltip placement="top">
            <div slot="content">
              <div style="display: flex;width: 45vw;line-height: 1.5rem;">
                <div v-html="replaceLineBreaks(activeItem.roleDesc)">
                </div>
              </div>
            </div>
            <div class="jieshi-box-content" v-html="replaceLineBreaks(activeItem.roleDesc)">
            </div>
          </el-tooltip>
        </div>
      </el-col>
      <el-col :span="24" class="">
        <div class="jieshi-box-tishi d-flex">
          <img class="img-disble" src="../../assets/tishi-disable.png" style=""/><span class="">说明：以上为套路说明，请根据套路说明植入想要的<a style="color: #366EF4;text-decoration: underline;cursor: text;">故事事件</a></span>
        </div>
      </el-col>

      <!-- 问答 -->
      <template v-for="(item, index) in storyTemplateQuestions">
        <el-col :span="24" class="">
          <div class="question-box">
            <div class="question-box-title" v-html="replaceNumber(index,item.title)">
            </div>
            <div class="example-box d-flex" style="flex-direction: column;">
              <div class="question-box-example" >
                {{item.example}}
              </div>
              <div class="question-box-answer d-flex" :class="{'ly-textarea-error-border': item.id == checkQuestionId}">
                <div class="question-box-answer-input d-flex-1">
                  <el-input :class="{'ly-textarea-error': item.id == checkQuestionId}" :placeholder="getPlaceholder(item.id == checkQuestionId)" @input="inputChange(item, index)"
                    v-model="storyTemplateAnswers[index]" type="textarea" clearable :autosize="{ minRows: 1, maxRows: 10 }" show-word-limit maxlength="500" class="ly-textarea">
                  </el-input>
                </div>
                <div class="question-box-answer-btn" @click="handleQuestionClick(item)">
                  <img src="../../assets/aigen-icon.png" alt="" srcset="" /><span>AI生成</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </template>
    </el-row>

    <el-row :gutter="10" class="my-1" v-if="activeItem.id != '-1'">
      <!-- wenfeng -->
      <el-col :span="24" class="my-1">
        <span class="field-title">故事文风</span><span class="field-required">*</span>
      </el-col>
      <el-col :span="24" v-if="enums.ArticleStyleEnum">
        <el-select class="custom-select-v1"
            v-model="story.articleStyle"
            filterable
            size="mini"
            placeholder="文风">
          <el-option
            v-for="(item,index) in enums.ArticleStyleEnum.filter((item) => {
              return item.code != 3
            })"
            :label="item.desc"
            :key="index"
            :value="item.name"
          />
        </el-select>
      </el-col>
      <el-col :span="24" class="my-1">
        <span class="field-title">写作视角</span><span class="field-required">*</span>
      </el-col>
      <el-col :span="24" v-if="enums.WritingPerspectiveEnum">
        <el-radio-group v-model="story.writingPerspective">
          <el-radio :border="false" :label="item.name" :key="index" v-for="(item,index) in enums.WritingPerspectiveEnum">{{item.desc}}</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>

    <!-- 创意引导 -->
    <el-row :gutter="10" class="my-1"  v-if="notTemplateFlag">

      <el-col :span="24" class="my-1">
        <span class="field-title">故事元素</span><span class="field-required"></span>
      </el-col>
      <el-col :span="24">
        <el-input type="text" placeholder="非必填，多个用逗号分隔"
          v-model="story.storyIdeaElement" :rows="1" clearable class="story-idea no-border-input">
        </el-input>
        <div class="d-flex flex-between">
         <div class="story-idea-tag-box">
           <span @click="handleIdeaClick('element', itemHot.name)" class="story-idea-tag-box-tag pointer" :key="index" v-for="(itemHot,index) in synesthesiaElements">{{itemHot.name}}</span>
         </div>
         <div class="pointer get-more" @click="handleIdeaRef('element')">
           <img src="@/assets/random.png" style="width: 12px;padding-right: .25rem;"/><span style="">换一换</span>
         </div>
        </div>
      </el-col>

      <el-col :span="24" class="my-1">
        <span class="field-title">时下热点</span><span class="field-required"></span>
      </el-col>
      <el-col :span="24">
        <el-input type="text" placeholder="可选，多个用逗号分隔"
          v-model="story.storyHot" clearable :rows="1" class="story-idea no-border-input">
        </el-input>

        <div class="d-flex flex-between">
          <div class="story-idea-tag-box">
            <span @click="handleIdeaClick('hot', itemHot.content)" class="story-idea-tag-box-tag pointer" :key="index" v-for="(itemHot,index) in hots">{{itemHot.content}}</span>
          </div>
          <div class="pointer get-more" @click="handleIdeaRef('hot')">
            <img src="@/assets/random.png" style="width: 12px;padding-right: .25rem;"/><span style="">换一换</span>
          </div>
        </div>
      </el-col>

      <el-col :span="24" class="my-1">
        <span class="field-title">其它想法</span>
      </el-col>
      <el-col :span="24">
        <el-input type="textarea" placeholder="请描述你想创作的故事…可以是简单的一句话或者是一大段设定"
          v-model="story.storyIdeaOther" clearable :rows="4" maxlength="2000" show-word-limit class="story-idea no-border-input">
        </el-input>
      </el-col>
    </el-row>

    <StoryDpTemplateDialog v-if="showDialogDpFlag" :enums="enums" ref="dp-template-ref"/>
    <StoryDpQuestionDialog v-if="showDialogDpQuestionFlag && activeQuestion.id" :storyTemplate="activeTemplate" :question="activeQuestion" ref="dp-question-ref"/>
  </div>

</template>
<script>
import * as StoryTemplateQuestionApi from '@/api/storytemplatequestion'
import * as StoryTemplateAnswerApi from '@/api/storytemplateanswer'
import * as StoryCpApi from '@/api/storycp'
import * as StoryTemplateApi from '@/api/storytemplate'
import * as EnumApi from '@/api/enums'
import * as StorySynesthesiaElementApi from '@/api/storysynesthesiaelement'
import StoryDpTemplateDialog from '@/components/StoryIdeaDialog/index_template'
import StoryDpQuestionDialog from '@/components/StoryIdeaDialog/index_question'
import * as StoryHotApi from '@/api/storyhot'
import { mapState, mapMutations} from 'vuex'
import * as ArticleApi from '@/api/article'
export default {
  name: 'StoryByTemplate',
  props: {
    enums: {
      type: Object,
      default: {}
    },
    channel: {
      type: String,
      default: 'workspace_index'
    },
  },
  components: {
    StoryDpTemplateDialog,StoryDpQuestionDialog
  },
  data() {
    return {
      activeItem: {id: '-1'},
      synesthesiaElements:[],
      cps:[],
      hots:[],
      storyTemplates:[],
      storyTemplateQuestions:[],
      storyTemplateAnswers:[],
      storyTemplateTags:[],
      tags: [],
      placeholder: '请填写完整的相关事件',
      checkQuestionId: '',
      queryForm: {
        name: '',
        templateType: 'GUANFANG',
        enableFlag: true,
        tag: '',
        current: '',
        size: 100
      },
      rules: {
      }
    }
  },
  mounted() {
    console.log('mounted', this.story);
    if(this.story.id && Number(this.story.id)>0){
      // 存在story
      this.handleTemplateView()
    }else{
      this.activeItem = {id: '-1'}
      this.setStory({id: '', articleType: 'FEEL_GOOD', articleGenType: 'GEN_BY_ZHIHU', articleStyle: 'ZHENGWEN', writingPerspective: 'FIRST_PERSON', storyCp: '', storyHot: '', storyIdeaElement: ''})
    }

    this.handleIdeaRef('element')
    this.handleIdeaRef('hot')
    this.handleIdeaRef('cp')

    window.addEventListener('resize', this.forceUpdate);

  },
  computed: {
    ...mapState(['user', 'story', 'showDialogDpFlag', 'showDialogDpQuestionFlag', 'activeQuestion', 'activeTemplate']),
    notTemplateFlag() {
      console.log('notTemplateFlag activeItem:', this.activeItem)
      return this.activeItem.id && Number(this.activeItem.id)==0;
    },
    showHeader() {
      return window.innerWidth <= 768
    },
    nextBtnDisabled() {
      console.log('nextBtnDisabled activeItem:', this.activeItem, this.storyTemplateAnswers)
      return this.activeItem.id == '-1'? true : (this.storyTemplateAnswers.some(item => !item || item.trim() == '') && Number(this.activeItem.id)>0)
    },
  },
  created() {
    console.log('created');
  },
  methods: {
    ...mapMutations(['setStory', 'setShowDialogDpFlag', 'setShowDialogDpQuestionFlag', 'setActiveQuestion', 'setActiveTemplate']),
    replaceNumber(index, text){
      if(!text) {
        return ''
      }
      // ① ② ③
      let e1 = this.extractTextBetween(text, '①', '②')
      let es = []
      es.push('<el-tooltip placement="top">')

      es.push('<div slot="content">')
      es.push(e1)
      es.push('</div>')

      es.push('<span style="color:#366EF4;">①</span>')
      es.push('</el-tooltip>')
      let tip1 = es.join()

      let e2 = this.extractTextBetween(text, '②' ,'③')
      es = []
      es.push('<el-tooltip placement="top">')

      es.push('<div slot="content">')
      es.push(e2)
      es.push('</div>')

      es.push('<span style="color:#366EF4;">②</span>')
      es.push('</el-tooltip>')
      let tip2 = es.join()

      let e3 = text.substr(text.indexOf('③')+1)
      es = []
      es.push('<el-tooltip placement="top">')

      es.push('<div slot="content">')
      es.push(e3)
      es.push('</div>')

      es.push('<span style="color:#366EF4;">③</span>')
      es.push('</el-tooltip>')
      let tip3 = es.join()

      return text?'<span class="field-required">*</span>'+(index+1)+'. '
      +text.trim().replace('①', '<span style="color:#366EF4;">①</span>')
      .replace('②', '<span style="color:#366EF4;">②</span>')
      .replace('⑩', '<span style="color:#366EF4;">⑩</span>')
      .replace('④', '<span style="color:#366EF4;">④</span>')
      .replace('⑤', '<span style="color:#366EF4;">⑤</span>')
      .replace('⑥', '<span style="color:#366EF4;">⑥</span>')
      .replace('⑦', '<span style="color:#366EF4;">⑦</span>')
      .replace('⑧', '<span style="color:#366EF4;">⑧</span>')
      .replace('⑨', '<span style="color:#366EF4;">⑨</span>')
      .replace('③', '<span style="color:#366EF4;">③</span>'):'';
      // return text?'<span class="field-required">*</span>'+(index+1)+'. '+text.trim().replace('①', tip1).replace('②', tip2).replace('③', tip3):'';
    },
    extractTextBetween(str, startChar, endChar) {
      //str?str.replace(/\\n/g, '\n').replace(/\n/g, '<br>'):"";
      // 构建正则表达式，使用捕获组来提取两个字符之间的文本
      const regex = new RegExp(`${startChar}(.*?)${endChar}`);
      // 使用 match 方法搜索字符串
      const match = str.match(regex);
      // 如果找到匹配项，返回第一个捕获组的内容（索引 1）
      return match ? match[1] : null;
    },
    splitByEmptyLines(str) {
      // 正则表达式匹配一个或多个换行符，可能前后有空白字符
      // const regex = /(\s*\n\s*)+/;
       // 正则表达式匹配两个换行符之间没有其他字符的情况
        const regex = /\n\s*\n/;
      return str?str.split(regex):'';
    },
    replaceLineBreaks(str) {
      return str?str.replace(/\\n/g, '\n').replace(/\n/g, '<br>'):"";
    },
    inputChange(item, index){
      //创建 or 更新 answer
      StoryTemplateAnswerApi.create({fromType: 'qa_answer_title_change', questionId: item.id, storyId: this.story.id, templateId: this.story.storyTemplateId, accountId:this.user.id, answer: this.storyTemplateAnswers[index]}).then(res => {
        let result = res.data.data;
        this.checkQuestionId = ''
      });
    },
    createOrUpdateStroy(from) {//0-下一步 1-AI生成 2-开始生成 3-重新生成 4-确认模板

      if(from == 0) {
        if ((!this.story.articleType || !this.story.articleType.trim()) && this.notTemplateFlag) {
            this.$message.error('请选择故事类型');
            return false;
        }
        if (!this.story.articleStyle || !this.story.articleStyle.trim()) {
            this.$message.error('请选择故事文风');
            return false;
        }
        if (!this.story.writingPerspective || !this.story.writingPerspective.trim()) {
            this.$message.error('请选择写作视角');
            return false;
        }
        if (!this.notTemplateFlag && (!this.story.storyTemplateId || this.story.storyTemplateId == '0')) {
            this.$message.error('请选择模板');
            return false;
        }
        let questionIndex = 0
        this.storyTemplateAnswers.forEach((item, index) => {
          console.log('answer', index, item);
          if(!item || item == ''){
            questionIndex = index
            this.checkQuestionId = this.storyTemplateQuestions[questionIndex].id
            this.$message.warning(this.getPlaceholder(true))
            throw new Error('eixt loop');
          }
        })
      }else if(from == 1){

      }else if(from == 2){

      }else if(from == 3){

      }else if(from == 4){
        // isLoading
        this.$parent.isLoading = false
      }


      if (this.notTemplateFlag) {
          this.story.storyTemplateId = '0'
      }else{
        this.story.storyCp = ''
        this.story.storyHot = ''
        this.story.storyIdeaElement = ''
        this.story.storyIdeaOther = ''
      }

      let params = {}
      if(this.notTemplateFlag){// 不使用模板
        params = {
          id: this.story.id,
          articleType: this.story.articleType,
          articleStyle: this.story.articleStyle,
          writingPerspective: this.story.writingPerspective,
          storyHot: this.story.storyHot,
          storyIdeaElement: this.story.storyIdeaElement,
          storyIdeaOther: this.story.storyIdeaOther,
          storyTemplateId: 0
        }
      }else{// 模板模式
        params = {
          id: this.story.id,
          articleStyle: this.story.articleStyle,
          writingPerspective: this.story.writingPerspective,
          storyTemplateId: this.activeItem.id
        }
      }
      console.log('params', params,'story', this.story);

      if(this.story.id && Number(this.story.id)>0){
        // 存在story
        console.log('update params=', params);
        // return false
        ArticleApi.update(params).then(res => {
          let data = res.data.data
          this.setStory(data)
          this.goNext(from)
        })
      }else{
        console.log('create params=', params);
        // return false
        ArticleApi.create(this.story).then(res => {
            if(res.data.code == 0){
              let data = res.data.data
              console.log('new dp story', this.story)
              this.setStory(data)
              this.goNext(from)
            }else{
              this.$message.error(res.data.msg);
            }
        })
      }
    },
    goNext(from){
      if(from == 0){
        setTimeout(()=>{
          this.$router.push('/workspace/gen1');
        }, 300)
      }else if(from == 4){
        this.handeTemplateQuestion()
      }
    },
    handeTemplateQuestion(){
      if(!this.user.id || !this.story.id){
        return false
      }
      let templateId = this.activeItem.id
      StoryTemplateQuestionApi.page({templateId, accountId:this.user.id, storyId:this.story.id,size: 20}).then(res => {
        let result = res.data.data;
        this.storyTemplateQuestions = result.records
        // 初始化 answer
        this.storyTemplateQuestions.forEach((item, index) => {
          if(item.answerVo && item.answerVo.answer){
            this.storyTemplateAnswers[index] = item.answerVo.answer
          }else{
            this.storyTemplateAnswers[index] = ''
          }
        })
      });
    },
    handleTemplateView(){
      if(this.story.storyTemplateId && Number(this.story.storyTemplateId) == 0){
        this.activeItem = {id: '0'}
        return false
      }
      StoryTemplateApi.view(this.story.storyTemplateId).then(res => {
        let result = res.data.data;
        if(result){
          this.activeItem = result
          this.setActiveTemplate(result)
          // init question
          this.handeTemplateQuestion()
        }else{
          this.activeItem = {id: '0'}
        }
      });
    },
    handleIdeaRef(flag) {
      if(flag == 'element') {
        StorySynesthesiaElementApi.page({size: 100}).then(res => {
          let result = res.data.data;
          const shuffledArray = this.shuffleArray([...result.records])
          this.synesthesiaElements = shuffledArray.slice(0, 4);
          // this.synesthesiaElements = result.records
        });
      }else if(flag == 'hot') {
        StoryHotApi.page({size: 100}).then(res => {
          let result = res.data.data;
          const shuffledArray = this.shuffleArray([...result.records])
          this.hots = shuffledArray.slice(0, 4);
          // this.synesthesiaElements = result.records
        });
      }else if(flag == 'cp') {
        StoryCpApi.page({size: 100}).then(res => {
          let result = res.data.data;
          const shuffledArray = this.shuffleArray([...result.records])
          this.cps = shuffledArray.slice(0, 4);
          // this.synesthesiaElements = result.records
        });
      }
    },
    getPlaceholder(flag) {
      return flag?'请按顺序回答问题':'请填写完整的相关事件';
    },
    handleQuestionClick(question) {//AI 生成
      // 控制：确保前面的问题已经回答
      let questionIndex = 0
      this.storyTemplateAnswers.forEach((item, index) => {
        if(index < question.orderNumber-1 && (!item || !item.trim())){
          questionIndex = index
          this.checkQuestionId = this.storyTemplateQuestions[questionIndex].id
          this.$message.warning(this.getPlaceholder(true))
          throw new Error('eixt loop');
        }
      })

      this.createOrUpdateStroy(1)
      console.log('handleQuestionClick question=', question);
      this.setActiveQuestion(question)

      this.setShowDialogDpQuestionFlag(true);
    },
    handleIdeaClick(flag, text) {
      console.log('flag, text', flag, text);
      if(flag == 'element') {
        if(!this.story.storyIdeaElement){
          this.story.storyIdeaElement = text
        }else if(this.story.storyIdeaElement.indexOf(text) == -1){
          this.story.storyIdeaElement = this.story.storyIdeaElement +  ',' + text
        }
      }else if(flag == 'hot') {
        if(!this.story.storyHot){
          console.log('flag, text', flag, text);
          this.story.storyHot = text
          console.log('storyHot', this.story.storyHot);
          this.setStory(this.story)
        }else if(this.story.storyHot.indexOf(text) == -1){
          console.log('flag, text', flag, text);
          this.story.storyHot = this.story.storyHot +  ',' + text
        }
      }else if(flag == 'cp') {
        if(!this.story.storyCp){
          this.story.storyCp = text
        }else if(this.story.storyCp.indexOf(text) == -1){
          this.story.storyCp = this.story.storyCp +  ',' + text
        }
      }
    },
    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            // 生成从0到i的随机索引
            const j = Math.floor(Math.random() * (i + 1));
            // 交换元素
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    },
    forceUpdate() {
      this.$forceUpdate(); // 强制重新渲染，以更新计算属性
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.forceUpdate);
  },
}
</script>

<style scoped lang="scss">
$tagMT: .5rem;
.ly-textarea {
  /deep/ .el-textarea__inner {
      border: none;
      outline: none;
      box-shadow: none;
      background: #FFFFFF !important;
      text-align: justify;
      padding: .5rem;
      line-height: 2;
  }
  /deep/ .el-textarea__inner:hover,
  /deep/ .el-textarea__inner:focus {
      border-color: transparent;
  }
}
.ly-textarea-error {
  /deep/ .el-textarea__inner::placeholder {
    color: #ff0000; /* 红色 */
    // font-size: 14px; /* 根据需要调整字体大小 */
    opacity: 0.5; /* 降低透明度 */
    // border: 1px solid #ff0000;
  }
}
.ly-textarea-error-border {
  border: 1px solid #ff0000 !important;
}
.no-border-input {
  background: #FFFFFF;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #DCDCDC;
}
.story-idea-tag-box {
  margin-top: $tagMT;
  &-tag {
    display: inline-block;
    background: #F2F3FF;
    border-radius: 12px;
    font-weight: 400;
    font-size: 12px;
    color: #242424;
    padding: .25rem 1rem;
    margin: 0 .5rem .5rem 0;
    white-space: nowrap;
  }
}
.get-more {
  font-size: 12px;
  color: #0052D9;
  white-space: nowrap;
  margin-top: $tagMT;
}
/* 全局滚动条轨道的padding */
.model-box::-webkit-scrollbar-track {
  background-color: transparent;
  // padding: 20px 0; /* 上下padding */
  margin: 1rem 0;
}
/* 滚动条的样式 */
.model-box::-webkit-scrollbar {
  height: 10px;
  margin: 1rem 0;
  background-color: transparent;
}
.model-box::-webkit-scrollbar-thumb {
  background-color: rgba(54,110,244,0.1);
}
.template-box {
  height: 100px;
}
/* 定义 CSS 变量 */
.custom-placeholder .el-input__inner {
  --placeholder-color: #ff0000; /* 红色 */
}
/* 使用 CSS 变量 */
.custom-placeholder .el-input__inner::placeholder {
  color: var(--placeholder-color);
}

/* 定义 CSS 变量 */
.custom-textarea-placeholder .el-textarea__inner {
  --placeholder-color: #ff0000; /* 红色 */
  --placeholder-font-size: 14px; /* 字体大小 */
  --placeholder-opacity: 0.5; /* 透明度 */
}
/* 使用 CSS 变量 */
.custom-textarea-placeholder .el-textarea__inner::placeholder {
  color: var(--placeholder-color);
  font-size: var(--placeholder-font-size);
  opacity: var(--placeholder-opacity);
}
.example-box {

}
.example-box:hover .question-box-example{
   opacity: 1;
   height: auto;
}
.question-box {
  margin-top: 2rem;
  &-title {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    line-height: 1.5rem;
  }
  &-example {
    background: #FFFFFF;
    color: #366EF4;
    font-size: 12px;
    padding: .5rem 0;
    opacity: 0;
    height: 0;
  }
  &-answer {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #E5E7FC;
    &-input {

    }
    &-input:hover + .question-box-example{
      opacity: 1;
    }
    &-btn {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      color: rgba(0,0,0,0.6);
      padding-right: .5rem;
      cursor: pointer;
      img {
        width: 28px;
        height: 28px;
      }
    }
    &-btn:hover {
      color: #366EF4;
    }
  }
  &-answer:hover {
    border: 1px solid #366EF4;
  }
}
.hidden-element {
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none; /* 防止干扰input元素的点击事件 */
}
.trigger-input:hover + .hidden-element {
  opacity: 1;
}

.jieshi-box {
  background: linear-gradient(270deg, #F3F4FF 95%, #E8EAFF 100%);
  border-radius: 6px;
  padding: 1rem;
  &-title{
    font-weight: 600;
    font-size: 18px;
    color: rgba(0,0,0,0.8);
    align-items: center;
    .img-header {
      width: 24px;
      height: 24px;
      margin-right: .5rem;
    }
    .img-tail {
      width: 18px;
      height: 18px;
      margin-left: .5rem;
    }
  }
  &-content{
    font-weight: 400;
    font-size: 12px;
    color: rgba(0,0,0,0.81);
    padding: .5rem 0;
    line-height: 1.5;
    height: 4.5rem;
    -webkit-line-clamp: 4; /* 要显示的行数 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    // text-overflow: ellipsis; /* 超出部分显示省略号 */
    display: -webkit-box;
  }
  &-content:hover {
    overflow: auto;
  }
  &-tishi{
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    .img-disble {
      width: 18px;
      height: 18px;
      margin-right: .5rem;
    }
  }
}
.more-btn {
  display: none; /* 默认不显示 */
}
.model-box {
  // margin: .5rem;
  &-item {
    // width: 14.5rem;
    // padding: 1rem;
    background: linear-gradient( 270deg, #F3F4FF 0%, #E8EAFF 100%);
    border-radius: 14px;
    box-sizing: border-box; /* 边框计算在宽度内 */
    background: linear-gradient( 270deg, #F3F4FF 0%, #E8EAFF 100%);
    overflow: hidden;
    color: #303133;
    &-title {
      font-weight: 600;
      font-size: 1rem;
      color: rgba(0,0,0,0.9);
    }
    &-content {
      // font-size: .9rem;
      // color: rgba(0,0,0,0.6);
      // line-height: 1.25rem;
      // text-align: justify;
      // // margin-top: 1rem;
      // margin-bottom: 2rem;
    }
    &-tag {
      &-item{
        display: inline-block;
        background: #F2F3FF;
        border-radius: 12px;
        font-weight: 400;
        font-size: 12px;
        color: #366EF4;
        padding: .25rem 1rem;
        margin-right: .5rem;
        white-space: nowrap;
      }
    }
  }
}
.multiline-ellipsis {
  position: relative; /* 为伪元素定位做准备 */
  max-height: 6em; /* 根据内容和行高设置最大高度，这里假设每行2em */
  overflow: hidden; /* 隐藏溢出的内容 */
  line-height: 1.25em; /* 设置行高，与最大高度保持一致 */
  text-align: justify; /* 文本两端对齐 */
  // padding-right: 20px; /* 为省略号留出空间 */
}

.multiline-ellipsis::after {
  content: '...';
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  color: #FFFFFF;
  padding-left: 40px; /* 渐变背景的长度 */
  background: linear-gradient(to right, transparent, white 50%);
  /* 渐变背景从透明到白色，覆盖超出的文本 */
}
.ellipsis4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.custom-select-v1 {
  /deep/ .el-input__inner {
    border-radius: 20px;
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: 12px;
    background: #FFFFFF;
    border: 1px solid #EBEEF5 !important;
  }
  /deep/ .el-input__suffix {
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
}
.field-content {
  color: rgba(0,0,0,0.6);
}
/deep/ .el-input__inner {
    border: none; /* 去除边框 */
    outline: none; /* 去除聚焦时的轮廓 */
    box-shadow: none; /* 去除阴影 */
    background: transparent;
}
/deep/ .el-select {
    background: #ffffff;
}
</style>
<style>
.el-tooltip__popper {
  background: rgba(0,0,0,0.8) !important;
  border-radius: 16px !important;
}
.el-tooltip__popper[x-placement^=top] .popper__arrow:after{
    border-top-color:  rgba(0,0,0,0.8) !important;
}
</style>
