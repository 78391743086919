<template>
  <div class="app-table-container">
    <!-- 数据表格 -->
    <el-table
      stripe
      :header-cell-style="{ background:'#eef1f6', color:'#606266' }"
      :data="tableData"
      :height="tableHeight"
      ref="tableContainer"
      class="table-container">

      <el-table-column
        fixed
        prop="name"
        label="节点模型"
        min-width="100px"
        />

        <el-table-column
          prop="plan"
          label="备用模型"
          min-width="80px">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" size="mini" round>
              {{scope.row.plan}}
            </el-button>
          </template>
        </el-table-column>

      <el-table-column
        prop="enableFlag"
        label="渠道"
        min-width="80px">
        <template slot-scope="scope">
          <el-button :disabled="scope.row.name != 'claude_35_sonnet_20240620'" @click="handleEnable(scope.row)"  :type="scope.row.enableFlag?'warning':'success'" size="mini" round>
            {{scope.row.enableFlag?'自建服务':'二包'}}
          </el-button>
        </template>
      </el-table-column>

    </el-table>

    <div style="padding: 1rem .5rem;">
      <h3>渠道</h3>
      <p>目前只有两个，一个是二包，一个是自建服务（Claude官方模型）</p>
      <h3>节点模型</h3>
      <p>工作流中，大模型节点的模型</p>
      <h3>备用模型</h3>
      <p>如果节点模型出现异常，可以切换其他模型；二包正常时，备用模型置空或者与节点模型一致</p>
    </div>

    <!-- 设置 弹窗 -->
    <el-dialog :append-to-body="true" class="custom-dialog"
      title="切换备用模型"
      :visible.sync="showSettingFlag"
      @close="close"
      style="text-align: center;margin: 0 auto;">
        <div class="d-flex flex-y-center">
          <el-radio-group v-model="plan" @input="change">
            <el-radio :border="false" :label="item.name" :key="index" v-for="(item,index) in tableData">{{item.name}}</el-radio>
          </el-radio-group>
        </div>
        <div class="" style="margin-top: 3rem; margin-bottom: 1rem;">
          <el-button type="warning" class="custom-button" style="border-radius: 10px;"  @click="handleTest()">测试</el-button>
          <el-button v-if="planText == '[DONE]'" type="success" class="custom-button" style="border-radius: 10px;">可用:{{planText}}</el-button>
          <el-button v-if="planText != '[DONE]' && planText != ''" type="danger" class="custom-button" style="border-radius: 10px;">异常:{{planText}}</el-button>
        </div>
        <div class="" style="margin-top: 3rem; margin-bottom: 1rem;">
          <el-button type="danger" class="custom-button" style="border-radius: 10px;"  @click="handleRemove()">移除</el-button>
        </div>
        <div class="" style="margin-top: 3rem; margin-bottom: 1rem;">
          <el-button type="primary" class="custom-button" style="border-radius: 10px;"  @click="handleUpdate()">保存</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>

import * as TuteworkApi from '@/api/tutework'
export default {
  name: 'Tutework',
  components: {

  },
  data() {
    return {
      formLabelWidth: '80px',
      plan: '',
      planText: '',
    	currentItem: {},
      tableHeight: 0,
      tableData: [],
      showSettingFlag: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.$refs.tableContainer.$el.offsetHeight;
    })
    this.handlePage()
  },
  methods: {
    change(event){
      this.planText = ''
    },
    handleClick(item) {
      this.currentItem = item
      this.plan = item.plan
      this.showSettingFlag = true
    },
    handleTest() {
      TuteworkApi.update({id: this.currentItem.id, plan: this.plan, fromType: 'handle_test'}).then(res => {
        let result = res.data.data;
        this.planText = result.brief
      });
    },
    handleUpdate() {
      TuteworkApi.update({id: this.currentItem.id, plan: this.plan}).then(res => {
        this.handlePage();
        this.showSettingFlag = false
      });
    },
    handleRemove() {
      this.$confirm('', '确定移除备用模型吗', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        this.showSettingFlag = false
        TuteworkApi.update({id: this.currentItem.id, plan: ''}).then(res => {
          this.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleEnable(item) {
      this.$confirm('', '确定切换渠道吗', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        TuteworkApi.update({id: item.id, enableFlag: !item.enableFlag}).then(res => {
          this.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handlePage() {
      TuteworkApi.page({}).then(res => {
        let result = res.data.data;
        this.tableData = result.records
      })
    },
    close() {
      this.showSettingFlag = false
      this.planText = ''
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-radio {
  padding: 1rem;
}
</style>
