<template>
  <div class="d-flex flex-y-center writer-box-content-step">
    <div class="pointer" style="padding: 0.3rem 0.3rem 0 0;" @click="goHome()">
      <i class="" style="font-size: 1.5rem;"><img src="@/assets/icon-home.png" width="40" height="40" alt=""></i>
    </div>
    <div style="flex:1">
      <el-steps :active="active" finish-status="success" simple>
        <!-- <el-step title="故事要素" @click.native="$router.push('/workspace/genbyoutline')" status="story.stepNumber"></el-step>
        <el-step title="故事大纲" @click.native="$router.push('/workspace/genbyoutline2')"></el-step>
        <el-step title="故事正文" @click.native="$router.push('/workspace/genbyoutline3')"></el-step>
        <el-step title="全文预览" @click.native="$router.push('/workspace/genbyoutline4')"></el-step> -->
        <el-step
          :class="{'pointer': ( index < story.stepNumber+1), 'current-steps': index == active, 'unclick': ( index > story.stepNumber+1)}"
          v-for="(item, index) in steps"
          :key="index"
          :title="item.title"
          :description="item.description"
          :status="item.status"
          @click.native="stepClick(index)">
        </el-step>
      </el-steps>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'StepsWriter',
  props: {
    active: {// 当前激活的步骤 wait / process / finish / error / success
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      steps: [
        { title: '故事设置', description: '', status: 'finish', route: '/workspace/gen' },
        { title: '故事要素', description: '', status: 'finish', route: '/workspace/gen1' },
        { title: '故事大纲', description: '', status: 'finish', route: '/workspace/gen2' },
        { title: '故事章纲', description: '', status: 'finish', route: '/workspace/gen3' },
        { title: '故事正文', description: '', status: 'finish', route: '/workspace/gen4' },
        { title: '全文预览', description: '', status: 'finish', route: '/workspace/gen5' },
        // 假设步骤 3 已完成，其他步骤未完成
      ],
    }
  },
  mounted() {
    this.steps.forEach((item, index) => {
      if(index < this.story.stepNumber+1){
        item.status = 'success'
        if(index == 4){
          item.status = this.story.stepStatus
        }
      }else{
        item.status = 'wait'
      }
    })
  },
  computed: {
  	...mapState(['user', 'story'])
  },
  created() {
  },
  methods: {
    ...mapMutations(['setActiveMenuItem','setStory']),
    stepClick(stepIndex) {
      console.log('stepNumber, stepIndex', this.story.stepNumber, stepIndex, this.steps[stepIndex]);
      // 如果步骤已完成，则更新 activeStep
      if (stepIndex < this.story.stepNumber+1) {
          this.$router.push(this.steps[stepIndex].route)
      }
      // this.$router.push(this.steps[stepIndex].route)
    },
    goHome(){
      this.setActiveMenuItem('1');
      this.$router.push('/home')
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-step.is-simple .el-step__head {
  align-items: center;
  display: flex;
}
/deep/ .el-step.is-simple .el-step__title{
  color: rgba(0,0,0,0.4);
}
/deep/ .el-step__title.is-success {
    color: rgba(0,0,0,0.9);
}
/deep/ .current-steps .el-step__main .el-step__title {
  font-weight: bold;
  color: #0052D9;
}
.current-steps {
  cursor: default;
}
.unclick {
  cursor: not-allowed;
}
</style>
