<template>
  <div class="writer-box writer-bg">
    <HeadNavWriter ref="headeNvWriterRef"/>

    <div class="writer-box-content">

      <!-- steps -->
      <StepsWriter :active="2"/>

      <!-- elements -->
      <div class="hidden-xs-only" style="font-weight: 600; font-size: 20px; color: #000000; line-height: 24px;margin: 1rem 0;">
        故事大纲
      </div>
      <div class="writer-box-content-editor">
        <el-input @change="changeInput" v-if="!isSkeleton" :disabled="!storyOutlineSimple" class="writer-box-input" id="msgbox" type="textarea" v-model="storyOutlineSimple" maxlength="4000" show-word-limit></el-input>
        <el-skeleton v-else :rows="8" animated />
      </div>

    </div>

    <!-- footer -->
    <div class="writer-box-footer" :class="{'button-flex-between': story.articleGenType != 'GEN_BY_OUTLINE'}">
      <span v-show="story.articleGenType != 'GEN_BY_OUTLINE' && storyOutlineSimples.length==0"></span>
      <span v-show="story.articleGenType != 'GEN_BY_OUTLINE' && storyOutlineSimples.length>0">
        <!-- <el-checkbox style="margin-right: .5rem;" v-model="checked4ElementVersion" @change="changeElementVersion"></el-checkbox> -->
        <el-select class="custom-select"
            v-model="story.storyOutlineSimpleId"
            filterable
            size="mini"
            :disabled="isLoading"
            placeholder="版本">
          <el-option
            v-for="itemVersion in storyOutlineSimples"
            @click.native.stop="contentChange(itemVersion)"
            :key="itemVersion.id"
            :label="'版本 ' + itemVersion.version"
            :value="itemVersion.id"
          />
        </el-select>
      </span>
      <span class="button-group">
        <el-button class="custom-button" :size="$root.buttonSize" type="primary" round @click="genStoryOutlineSimple()" :loading="isLoading" :disabled="isLoading" v-if="story.articleGenType != 'GEN_BY_OUTLINE' && storyOutlineSimples.length==0">生成大纲</el-button>
        <el-button class="custom-button" :size="$root.buttonSize" type="default" round icon="el-icon-refresh" @click="handleSave(1)" :loading="isLoading" :disabled="isLoading" v-if="story.articleGenType != 'GEN_BY_OUTLINE' && storyOutlineSimples.length>0">重新生成</el-button>
        <el-button class="custom-button" :size="$root.buttonSize" type="primary" @click="handleSave(2)" :disabled="isLoading" v-if="story.articleGenType != 'GEN_BY_OUTLINE' && storyOutlineSimples.length>0">生成章纲</el-button>
        <el-button class="custom-button" :size="$root.buttonSize" type="primary" @click="handleSave(0)" :disabled="isLoading" v-if="story.articleGenType == 'GEN_BY_OUTLINE' && storyOutlineSimples.length>0">下一步</el-button>
      </span>
    </div>
    <StoryIdeaDialog :enums="enums" channel="genbyzhihu_regenoutlinesimple" />
  </div>
</template>
<script>
import HeadNavWriter from '@/components/HeadNavWriter'
import StepsWriter from '@/components/StepsWriterZhihu'
import LyRichEditor from '@/components/RichEditor'
import * as EnumApi from '@/api/enums'
import * as ArticleApi from '@/api/article'
import * as StoryOutlineSimpleApi from '@/api/storyoutlinesimple'
import { mapState, mapMutations} from 'vuex'
import StoryIdeaDialog from '@/components/StoryIdeaDialog'
import sse from '@/utils/llmstream'
export default {
  name: 'Article',
  components: {
    LyRichEditor, HeadNavWriter, StepsWriter, StoryIdeaDialog
  },
  data() {
    return {
      checked4ElementVersion: false,
      enums: {},
      isLoading: false,
      isSkeleton: false,
    	targetPermit: 'article',
      storyOutlineSimple: '',
      // storyOutlineSimples: [{id: '0', content: '', version: 1}],
      storyOutlineSimples: [],
      storyOutlineSimpleTemplate: ``
    }
  },
  computed: {
  	...mapState(['user', 'story', 'dialogShowCostomContactFlag'])
  },
  mounted() {

    // console.log('story', this.story)
    this.handleEnum()
    this.handlePageStoryOutlineSimpleVersion()
  },
  methods: {
    ...mapMutations(['setStory', 'setShowDialogFlag', 'setDialogShowCostomContactFlag']),
    contentChange(item){
      // console.log('contentChange', item.content);
      this.storyOutlineSimple = item.content
      this.story.storyOutlineSimple = item.content
      this.story.storyOutlineSimpleId = item.id
      this.setStory(this.story)
      this.handleUpdateStory(item)
    },
    changeInput(event){
      console.log('changeInput', event);
      this.handleUpdateElement()
    },
    replaceLineBreaks(str) {
      return str?str.replace(/\\n/g, '\n').replace(/\n/g, '<br>'):"";
    },
    handleUpdateStory(item) {
      ArticleApi.update({id: this.story.id,
          storyOutlineSimpleId: item.id,
          storyOutlineSimple: item.content}
      ).then(res => {

      });
    },
    handleUpdateElement() {
      if(this.isLoading){// 正在生成内容
        return false;
      }
      if(!this.story.storyOutlineSimpleId || this.story.storyOutlineSimpleId == 0){
        return false
      }
      StoryOutlineSimpleApi.update({id: this.story.storyOutlineSimpleId,
          content: this.storyOutlineSimple}
      ).then(res => {
          this.story.storyOutlineSimple = this.storyOutlineSimple
          this.setStory(this.story)
      });
    },
    handlePageStoryOutlineSimpleVersion(flag){
      StoryOutlineSimpleApi.page({storyId: this.story.id, size: 100}).then(res => {
        let result = res.data.data;
        if(res.data.code == 0){
          if(result.records.length > 0){
            this.storyOutlineSimples = result.records;
            let currentElement = this.storyOutlineSimples.filter(i=>{return i.id==this.story.storyOutlineSimpleId})[0];
            if(currentElement){
              this.storyOutlineSimple = currentElement.content
              this.story.storyOutlineSimpleId = currentElement.id
            }else{
              this.story.storyOutlineSimpleId = this.storyOutlineSimples[result.records.length-1].id
              this.storyOutlineSimple = this.storyOutlineSimples[result.records.length-1].content
            }
          }else{
            // this.story.storyOutlineSimpleId = '0'
            // this.storyOutlineSimples = [{id: '0', content: '', version: 1}];
            this.storyOutlineSimples = [];
          }
          if ((!this.storyOutlineSimple || !this.storyOutlineSimple.trim()) && this.story.articleGenType == 'GEN_BY_OUTLINE' ) {
              this.storyOutlineSimple = this.storyOutlineSimpleTemplate;
          }
          this.story.storyOutlineSimple = this.storyOutlineSimple
          this.setStory(this.story)
          console.log('handlePageStoryOutlineSimpleVersion', this.story);
          if(result.records.length == 0 && this.story.articleGenType != 'GEN_BY_OUTLINE'){// 从创意开始，自动生成粗钢
            if(this.user.desktopFlag){
              this.genStoryOutlineSimple()
            }
          }
          if(flag && flag == 3 && this.story.articleGenType != 'GEN_BY_OUTLINE'){// 自动生成标题
            this.contentChange(this.storyOutlineSimples[this.storyOutlineSimples.length-1])
          }
        }
      })
    },
    handleEnum() {
      EnumApi.list().then(res => {
        let result = res.data.data;
        this.enums = result
      })
    },
    handleSave(flag) {
      if(flag == 0){//by outline 下一步
        if (!this.storyOutlineSimple || !this.storyOutlineSimple.trim() || this.storyOutlineSimple == this.storyOutlineSimpleTemplate) {
            this.$message.error('请输入故事粗钢');
            return false;
        }
        StoryOutlineSimpleApi.create({storyId: this.story.id, content: this.storyOutlineSimple}).then(res => {
            this.story.storyOutlineSimple = this.storyOutlineSimple
            console.log('handleSave')
            this.story.stepNumber = 2
            this.setStory(this.story)
            this.$router.push('/workspace/gen3');
        })
      }else if(flag == 1){// by idea : 重新生成 弹窗
        this.genStoryOutlineSimple()
      }else if(flag == 2){// by idea : 下一步
        if (!this.storyOutlineSimple || !this.storyOutlineSimple.trim() || this.storyOutlineSimple == this.storyOutlineSimpleTemplate) {
            this.$message.error('请输入故事粗钢');
            return false;
        }
        console.log('handleSave flag 2')
        this.story.stepNumber = 2
        this.setStory(this.story)
        this.$router.push('/workspace/gen3');
      }else if(flag == 3){//by idea AI 生成完之后保存版本
        this.handlePageStoryOutlineSimpleVersion(3)
      }
    },
    handleBlur() {
      // this.handleSave();
    },
    scrollToBottom() {
      const msgbox = document.getElementById("msgbox")
      msgbox.scrollTop = msgbox.scrollHeight;
    },
    handleStoryOutlineSimpleMsg(msg){
      this.isSkeleton = false;
      this.storyOutlineSimple = msg
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    genStoryOutlineSimple() {
        console.log('story::element', this.story);
        this.isLoading = true;
        this.isSkeleton = true;
        this.storyOutlineSimple = ''
        let params = Object.assign({}, this.story)
        params.articleId = this.story.id
        params.content = this.story.storyIdea
        params.platformCategory = 'WRITER_WEB'

// 1816677726778699778 old

        // 自由模式
        let workflowId = "1828719933174030338"
        // 模板模式
        if(this.story.storyTemplateId && this.story.storyTemplateId != '0'){
          workflowId = "1828803160358187010";
        }
        sse(workflowId, params, this.handleStoryOutlineSimpleMsg).then((result)=>{
          // console.log('result', result);
          //AI结束，处理逻辑
          this.isLoading = false;
          this.isSkeleton = false;
          this.storyOutlineSimple = result
          this.story.storyOutlineSimple = result
          this.setStory(this.story)
          // 保存版本
          this.handleSave(3)
          this.$refs.headeNvWriterRef.syncUser()
        })
    }
  }
}
</script>
<style scoped lang="scss">

</style>
